.k-panelbar {
  border-width: 0px;

  & > .k-item + .k-item,
  & > .k-panelbar-header + .k-panelbar-header {
    border-top-width: 0px;
  }

  & > .k-item,
  & > .k-panelbar-header {
    position: relative;
    & > .k-link{
      color: var(--font);
      background-color: var(--surface);
      position: relative;
      padding-inline: 0.5rem;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: var(--inputBorderColor);
        top: auto;
        bottom: 0;
        left: 0;
        z-index: 1;
      }

      * {
        position: relative;
        z-index: 2;
      }

      &.k-selected {
        color: var(--font);
        background-color: var(--surface);

        &:hover,
        &.k-hover,
        &.k-focus {
          color: var(--font);
          background-color: var(--surface);
        }
      }

      .k-panelbar-item-text {
        line-height: normal;
        padding-bottom: 0;
        background-color: transparent;
        padding-inline: 0.5rem;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
    .panel-bar-item-badge {
      position: absolute;
      right: 2.5rem;
      top: 25px;
      transform: translateY(-50%);
      background-color: var(--active);
      color: var(--surface);
      padding: 2px 10px;
      border-radius: 0.5rem;
      font-size: 0.6125rem;
      font-weight: 400;

    }
  }
  & > .k-panelbar-header {
    &.k-expanded {
      & > .k-link {
        background-color: var(--surface);
        color: var(--active);
        &.k-selected {
          background-color: var(--surface);
          color: var(--active);
          &.k-hover,
          &.k-focus {
            background-color: var(--surface);
            color: var(--active);
          }
        }
      }
      .k-icon {
        color: var(--active) !important;
      }
    }
  }

  .k-panelbar-item {
    .k-link {
      cursor: pointer;
    }
  }
}
